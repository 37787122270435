import {combineReducers} from 'redux';
import {reducer as toastrReducer} from 'react-redux-toastr'
import {CONSTANTS} from "./constants";

const initialState = {
  isLoading: false,
  isLoggedIn: false,
  pinCode: '',
  ticketInfo: null,
  isTickedCleared: false
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.actions.LOGIN_REQUEST:
      return {
        isLoading: true,
        isLoggedIn: false,
        pinCode: ''
      };
    case CONSTANTS.actions.LOGIN_SUCCESS:
      return {
        isLoading: false,
        isLoggedIn: true,
        pinCode: action.pinCode
      };
    case CONSTANTS.actions.LOGIN_FAILURE:
      return {
        isLoading: false,
        isLoggedIn: false,
        pinCode: ''
      };
    case CONSTANTS.actions.CHECK_TICKET_REQUEST:
      return {...state,
        isLoading: true,
        ticketInfo: null,
        isTickedCleared: false
      };

    case CONSTANTS.actions.CHECK_TICKET_SUCCESS:
      return {...state,
        isLoading: false,
        ticketInfo: action.ticketInfo
      };
    case CONSTANTS.actions.CHECK_TICKET_FAILURE:
      return {...state,
        isLoading: false,
        ticketInfo: null
      };

    case CONSTANTS.actions.VALIDATE_TICKET_REQUEST:
      return {
        ...state,
        isLoading: true,
        isTickedCleared: false
      };
    case CONSTANTS.actions.VALIDATE_TICKET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ticketInfo: action.ticketInfo
      };
    case CONSTANTS.actions.VALIDATE_TICKET_FAILURE:
      return {
        ...state,
        isLoading: false,
        ticketInfo: null
      };
    case CONSTANTS.actions.CLEAR_TICKET_INFO:
      return {
        ...state,
        ticketInfo: null,
        isTickedCleared: true
      };
    default:
      return state;
  }
};

export const rootReducer = combineReducers({
  toastr: toastrReducer,
  app: app
});