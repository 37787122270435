import * as React from "react";
import {connect} from "react-redux";
import {actions} from "../actions";
import Loader from "../components/Loader";
import {Redirect} from "react-router-dom";
import {Routing} from "../Routing";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.pinLength = 4;

    this.state = {
      pin: ''
    };
  }

  onBtnClicked(e) {
    e.preventDefault();

    if (this.state.pin.length === this.pinLength) {
      this.props.login(this.state.pin);
    }
  }

  onInputChanged(e) {
    if (Number.isInteger(parseInt(e.target.value)) && e.target.value.length <= this.pinLength) {
      this.setState({
        pin: e.target.value
      });
    }
  }

  render() {
    if (this.props.isLoggedIn) {
      return (
        <Redirect to={Routing.SCANNER_PAGE.url}/>
      );
    }

    return this.props.isLoading ? (<Loader/>) : (
      <div className="page-content" id="login-mobile">
        <div className="row">
          <h4 className="font-weight-semibold pin-title">
            Podaj PIN
          </h4>
          <div className="col s12">
            <input type="password" inputMode="numeric" onChange={e => {
              this.onInputChanged(e);
            }} className="validate" maxLength="4" pattern="[0-9]*" value={this.state.pin}/>
          </div>
          <button className="waves-effect waves-light btn login-button"
                  disabled={this.state.pin.length !== this.pinLength}
                  onClick={e => {
            this.onBtnClicked(e);
          }}>
            Zaloguj
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.app.isLoading,
    isLoggedIn: state.app.isLoggedIn
  }
};

export default connect(mapStateToProps, actions)(LoginPage);