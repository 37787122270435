import * as React from "react";
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import './App.css';
import {Switch, withRouter} from 'react-router-dom';
import {Routing} from "./Routing";
import {connect} from "react-redux";
import ReduxToastr from "react-redux-toastr";
import Loader from "./components/Loader";
import {actions} from "./actions";

class App extends React.Component {
  render() {
    const routes = [];

    for (let key in Routing) {
      routes.push(Routing[key].getRoute(this.props.isLoggedIn));
    }

    return (
      <div>
        <ReduxToastr
          timeOut={2500}
          preventDuplicates
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick/>
        {this.props.isLoading ? (<Loader/>) : (<Switch>{routes}</Switch>)}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.app.isLoading,
    isLoggedIn: state.app.isLoggedIn
  };
};

export default withRouter(connect(mapStateToProps, actions)(App))