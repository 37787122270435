import * as React from "react";
import {connect} from "react-redux";
import {actions} from "../actions";
import QrReader from 'react-qr-reader'
import Loader from "../components/Loader";
import {Redirect} from "react-router-dom";
import {Routing} from "../Routing";
import {toastr} from 'react-redux-toastr'

class ScannerPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ticketNumber: ''
    };
  }

  componentDidMount() {

  }

  onCheckTicketBtnClicked() {
    this.props.checkTicket(this.props.pinCode, this.state.ticketNumber);
  }

  handleScan(data) {
    if (data) {
      this.setState({
        ticketNumber: parseInt(data)
      })
    }
  }

  handleError(err) {
    toastr.error(err.name, err.message);
  }

  render() {
    if (this.props.ticketInfo) {
      return (
          <Redirect to={Routing.TICKET_PAGE.url}/>
      );
    }

    return this.props.isLoading ? (<Loader/>) : (
        <div className="page-content" id="ticket-code-mobile">
          <div className="row">
            <h4 className="font-weight-semibold check-title">
              Zeskanuj <br/> lub <br/> wprowadź numer biletu
            </h4>
            <div className="col s12">
              <input type="number" name="ticket-number" value={this.state.ticketNumber}
                     onChange={(e) => {
                       this.setState({ticketNumber: e.target.value})
                     }} className="validate"/>
            </div>
            <button className="waves-effect waves-light btn check-ticket-button"
                    disabled={!this.state.ticketNumber}
                    onClick={e => {this.onCheckTicketBtnClicked(e)}}>
              Sprawdź
            </button>
            {process.env.IS_DEV ? "" :
              <div className="td-qr-scanner">
                <QrReader
                  delay={300}
                  onError={data =>{this.handleError(data)}}
                  onScan={data => {this.handleScan(data)}}
                />
              </div>
                }
          </div>
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    pinCode: state.app.pinCode,
    isLoading: state.app.isLoading,
    ticketInfo: state.app.ticketInfo
  }
};

export default connect(mapStateToProps, actions)(ScannerPage);