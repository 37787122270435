import {CONSTANTS} from "./constants";
import {REQUESTS} from "./requests";
import {toastr} from 'react-redux-toastr'

const generateUrl = (apiUrl) => {
  const proxyUrl = process.env.PROXY_URL;
  return proxyUrl && proxyUrl !== '' ? `${proxyUrl}?redirect_url=${apiUrl}` : apiUrl;
};

const login = (pinCode) => {
  return dispatch => {
    dispatch({type: CONSTANTS.actions.LOGIN_REQUEST, pinCode});

    const apiUrl = `${process.env.API_URL}${REQUESTS.LOGIN}`;

    fetch(generateUrl(apiUrl), {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: `pin=${pinCode}`
    })
      .then(response => response.json())
      .then(parsedResponse => {
        if (parsedResponse.success) {
          if (parsedResponse.message) {
            toastr.success(parsedResponse.message);
          }
          dispatch({type: CONSTANTS.actions.LOGIN_SUCCESS, pinCode});
        } else {
          if (parsedResponse.message) {
            toastr.error(parsedResponse.message);
          }
          dispatch({type: CONSTANTS.actions.LOGIN_FAILURE});
        }
      })
      .catch(error => {
        toastr.error('Wystąpił błąd: ', error.message);
        //dispatch({type: CONSTANTS.actions.LOGIN_SUCCESS, pinCode});
        dispatch({type: CONSTANTS.actions.LOGIN_FAILURE});
      });
  };
};

const checkTicket = (pinCode, orderId) => {
  return dispatch => {
    dispatch({type: CONSTANTS.actions.CHECK_TICKET_REQUEST});

    const apiUrl = `${process.env.API_URL}${REQUESTS.CHECK_TICKET}`;

    fetch(generateUrl(apiUrl), {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: `pin=${pinCode}&order_id=${orderId}`
    })
        .then(response => response.json())
        .then(parsedResponse => {
          console.log(parsedResponse);
          if (parsedResponse.success) {
            if (parsedResponse.message) {
              toastr.success(parsedResponse.message);
            }
            dispatch({type: CONSTANTS.actions.CHECK_TICKET_SUCCESS, ticketInfo: parsedResponse.data});
          } else {
            if (parsedResponse.message) {
              toastr.error(parsedResponse.message);
            }
            dispatch({type: CONSTANTS.actions.CHECK_TICKET_FAILURE});
          }
        })
        .catch(error => {
          toastr.error('Wystąpił błąd: ', error.message);
          //dispatch({type: CONSTANTS.actions.LOGIN_SUCCESS, pinCode});
          dispatch({type: CONSTANTS.actions.CHECK_TICKET_FAILURE});
        });
  };
};

const validateTicket = (pinCode, orderId) => {
  return dispatch => {
    dispatch({type: CONSTANTS.actions.VALIDATE_TICKET_REQUEST});

    const apiUrl = `${process.env.API_URL}${REQUESTS.VALIDATE_TICKET}`;

    fetch(generateUrl(apiUrl), {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: `pin=${pinCode}&order_id=${orderId}`
    })
      .then(response => response.json())
      .then(parsedResponse => {
        if (parsedResponse.success) {
          if (parsedResponse.message) {
            toastr.success(parsedResponse.message);
          }

          dispatch({type: CONSTANTS.actions.VALIDATE_TICKET_SUCCESS, ticketInfo: parsedResponse.data});
        } else {
          if (parsedResponse.message) {
            toastr.error(parsedResponse.message);
          }
          dispatch({type: CONSTANTS.actions.VALIDATE_TICKET_FAILURE});
        }
      })
      .catch(error => {
        toastr.error('Wystąpił błąd: ', error.message);
        dispatch({type: CONSTANTS.actions.VALIDATE_TICKET_FAILURE});
      });
  };
};

const clearTicketInfo = () => {
  return {
    type: CONSTANTS.actions.CLEAR_TICKET_INFO
  };
};

export const actions = {
  login,
  checkTicket,
  validateTicket,
  clearTicketInfo
};