import {Redirect, Route} from "react-router-dom";
import React from "react";
import NotFoundPage from "./pages/NotFoundPage";
import LoginPage from "./pages/LoginPage";
import ScannerPage from "./pages/ScannerPage";
import TicketPage from "./pages/TicketPage";

class RouteConfig {
  constructor(url, component) {
    this.url = url;
    this.component = component;
  }

  getRoute(isLoggedIn = false) {
    const route = this.url === Routing.NOT_FOUND.url ?
      <Route key={btoa(this.url)} component={this.component}/> :
      <Route key={btoa(this.url)} path={this.url} exact component={this.component}/>;

    return (isLoggedIn || this.url === Routing.LOGIN_PAGE.url ?
      route
      : <Redirect key={btoa(this.url)} to={Routing.LOGIN_PAGE.url}/>);
  }
}

export const Routing = {
  LOGIN_PAGE: new RouteConfig('/', LoginPage),
  SCANNER_PAGE: new RouteConfig('/skaner', ScannerPage),
  TICKET_PAGE: new RouteConfig('/bilet', TicketPage),

  NOT_FOUND: new RouteConfig('/404', NotFoundPage)
};