export const CONSTANTS = {
  actions: {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',

    CHECK_TICKET_REQUEST: 'CHECK_TICKET_REQUEST',
    CHECK_TICKET_SUCCESS: 'CHECK_TICKET_SUCCESS',
    CHECK_TICKET_FAILURE: 'CHECK_TICKET_FAILURE',

    VALIDATE_TICKET_REQUEST: 'VALIDATE_TICKET_REQUEST',
    VALIDATE_TICKET_SUCCESS: 'VALIDATE_TICKET_SUCCESS',
    VALIDATE_TICKET_FAILURE: 'VALIDATE_TICKET_FAILURE',

    CLEAR_TICKET_INFO: 'CLEAR_TICKET_INFO'
  }
};