import * as React from "react";
import {connect} from "react-redux";
import {actions} from "../actions";
import Loader from "../components/Loader";
import {Redirect} from "react-router-dom";
import {Routing} from "../Routing";
import {toastr} from "react-redux-toastr";

class TicketPage extends React.Component {
  onValidateButtonClicked(e) {
    e.preventDefault();

    if (this.props.ticketInfo) {
      this.props.validateTicket(this.props.pinCode, this.props.ticketInfo.order_id);
    }
  }

  onReturnBtnClicked(e) {
    e.preventDefault();

    this.props.clearTicketInfo();
  }

  render() {
    if (!this.props.ticketInfo) {
      if (!this.props.isTickedCleared) {
        toastr.error('Wystąpił błąd: ', 'Proszę zeskanować bilet ponownie');
      }
      return (<Redirect to={Routing.SCANNER_PAGE.url} />);
    }

    return this.props.isLoading ? (<Loader/>) : (
      <div className="page-content" id="ticket-details-mobile">
        <div className="row">
          <h3 className="font-weight-semibold details-title">
            Bilet nr {this.props.ticketInfo.order_id}
          </h3>
          <div className="ticket-details">
            <div className="little_row">
              <span>Imię:</span>
              <h4>{this.props.ticketInfo.first_name}</h4>
            </div>
            <div className="little_row">
              <span>Nazwisko:</span>
              <h4>{this.props.ticketInfo.last_name}</h4>
            </div>
            <div className="little_row">
              <span>Adres e-mail:</span>
              <h4>{this.props.ticketInfo.email}</h4>
            </div>
            <div className="little_row">
              <span>Data rejsu</span>
              <h4>{this.props.ticketInfo.date}</h4>
            </div>
            {this.props.ticketInfo.regular_quantity && this.props.ticketInfo.regular_quantity > 0 ? (
              <div className="little_row">
                <span>Bilet normalny:</span>
                <h3>{this.props.ticketInfo.regular_quantity}</h3>
              </div>
            ) : null}
            {this.props.ticketInfo.discounted_quantity && this.props.ticketInfo.discounted_quantity > 0 ? (
              <div className="little_row">
                <span>Bilet ulgowy:</span>
                <h3 id="discount">{this.props.ticketInfo.discounted_quantity}</h3>
              </div>
            ) : null}

            <div className="little_row">
              <span>Status biletu:</span>
              <h3>{this.props.ticketInfo.status}</h3>
            </div>
          </div>
          {this.props.ticketInfo.status !== 'Skasowany' && (
            <button className="waves-effect waves-light btn check-ticket-button mb-2"
                    onClick={e => {this.onValidateButtonClicked(e)}}>
              Kasuj
            </button>
          )}
          <br />
          <button className="waves-effect waves-light btn check-ticket-button"
                  onClick={e => {this.onReturnBtnClicked(e)}}>
            Wróć
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ticketInfo: state.app.ticketInfo,
    pinCode: state.app.pinCode,
    isLoading: state.app.isLoading,
    isTickedCleared: state.app.isTickedCleared
  }
};

export default connect(mapStateToProps, actions)(TicketPage);